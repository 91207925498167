import React from "react";
import './style.css';

function Skills() {
    return (
        <div className="all-skills">
            <h2>Skills</h2>
            <div className="skills--cards">
                <div class="cardm">
                    <div class="card">
                        FrontEnd
                    </div>

                    <div class="card2">
                        <div class="upper">
                            <div class="humidity">
                                <h3>Linguagens</h3>
                            </div>
                        </div>
                        <div class="lower">
                            <div className="languages">React JS</div>
                            <div className="languages">HTML</div>
                            <div className="languages">CSS</div>
                            <div className="languages">JavaScript</div>
                        </div>
                    </div>
                </div>
                <div class="cardm">
                    <div class="card">
                        BeckEnd
                    </div>

                    <div class="card2">
                        <div class="upper">
                            <div class="humidity">
                                <h3>Linguagens</h3>
                            </div>
                        </div>
                        <div class="lower">
                            <div className="languages">CSharp</div>
                            <div className="languages">.NET</div>
                            <div className="languages">Node JS</div>
                        </div>
                    </div>
                </div>
                <div class="cardm">
                    <div class="card">
                        Data Base
                    </div>

                    <div class="card2">
                        <div class="upper">
                            <div class="humidity">
                                <h3>Linguagens</h3>
                            </div>
                        </div>
                        <div class="lower">
                            <div className="languages">SQL Server</div>
                            <div className="languages">MySql</div>
                        </div>
                    </div>
                </div>
                <div class="cardm">
                    <div class="card">
                        Mobile
                    </div>

                    <div class="card2">
                        <div class="upper">
                            <div class="humidity">
                                <h3>Linguagens</h3>
                            </div>
                        </div>
                        <div class="lower">
                            <div className="languages">React Native</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills